<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    :header="'Registrar Movimiento de Caja: #' + data_mov_caja.id"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span
            ><strong>TIPO MOVIMIENTO: </strong>
            <span class="p-invalid">*</span></span
          >
          <Dropdown
            v-model="tipo_mov"
            :options="tp_movimientos"
            optionLabel="label"
            placeholder="Seleccione tipo Movimiento"
          />
          <small class="p-invalid" v-if="errors.tipo_mov">{{
            errors.tipo_mov[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span
            ><strong>AUTORIZADO POR: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText v-model="autorizado_por" />
          <small class="p-invalid" v-if="errors.autorizado_por">{{
            errors.autorizado_por[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span
            ><strong>M&Eacute;TODO DE PAGO:</strong>
            <small class="p-invalid">*</small></span
          >
          <Dropdown
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
          />
        </div>
        <div
          v-if="metodo_pagoSelect != 1"
          :class="
            metodo_pagoSelect == 1
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-3'
          "
        >
          <span
            ><strong>{{ metodPagoNombre }}: </strong></span
          >
          <InputNumber
            v-model="importe_metodos_pagos"
            :minFractionDigits="2"
            mode="decimal"
            :inputClass="'text-right'"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.importe_metodos_pagos">{{
            errors.importe_metodos_pagos[0]
          }}</small>
        </div>
        <div
          :class="
            metodo_pagoSelect == 1
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-3'
          "
        >
          <span
            ><strong>IMP. EFECTIVO: </strong
            ><span class="p-invalid">*</span></span
          >
          <InputNumber
            inputClass="text-right"
            v-model="importe"
            :minFractionDigits="2"
            mode="decimal"
            :min="0"
          ></InputNumber>
          <small class="p-invalid" v-if="errors.importe">{{
            errors.importe[0]
          }}</small>
        </div>
        <div class="grid col-12" v-if="es_de_odontologia">
          <div class="field col-12 md:col-4">
            <span>&nbsp;</span>
            <div class="p-inputgroup" style="align-items: center">
              <i class="pi pi-info-circle mr-1 bg-blue-500 text-white p-1"></i>
              <span class="mr-1">
                <strong> ABONAR SALDO?</strong>
              </span>
              <Checkbox v-model="abonar_saldo" :binary="true" />
            </div>
          </div>
          <div class="field col-12 md:col-8" v-if="abonar_saldo">
            <span
              ><strong>BUSCAR PACIENTE:</strong
              ><span class="p-invalid">*</span></span
            >
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <AutoComplete
                v-model="nombre_cliente"
                :suggestions="clientes"
                field="nombre"
                v-tooltip.top="'Buscar paciente'"
                optionValue="id"
                @keyup.enter="buscarCliente"
              />
              <Button
                icon="pi pi-search"
                class="p-button-warning"
                v-tooltip.top="'Buscar paciente'"
                @click="buscarCliente"
              />
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <span
            ><strong>POR CONCEPTO DE:</strong>
            <span class="p-invalid">*</span></span
          >
          <Textarea
            v-model="concepto"
            rows="1"
            autoResize
            placeholder="Ingrese el concepto del movimiento de caja"
          />
          <small class="p-invalid" v-if="errors.concepto">{{
            errors.concepto[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarMovCaja"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import CajaService from "@/service/CajaService";
import MetodoPagoService from "@/service/MetodoPagoService.js";
import ClienteService from "@/service/ClienteService.js";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mov_caja: {
      type: Object,
      default() {
        return {};
      },
    },
    esdeodontologia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      es_de_odontologia: this.esdeodontologia,
      mostrarModal: this.show,
      data_mov_caja: this.mov_caja,
      enviado: false,
      errors: {},
      tipo_mov: { label: "INGRESO", value: 1 },
      tp_movimientos: [
        { label: "INGRESO", value: 1 },
        { label: "EGRESO", value: 0 },
      ],
      autorizado_por: "",
      importe: null,
      concepto: null,
      caja_id: null,
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      importe_metodos_pagos: null,
      clientes: [],
      nombre_cliente: "",
      abonar_saldo: false,
    };
  },
  cajaService: null,
  metodoPagoService: null,
  clienteService: null,
  created() {
    this.cajaService = new CajaService();
    this.metodoPagoService = new MetodoPagoService();
    this.clienteService = new ClienteService();
  },
  computed: {
    metodPagoNombre() {
      return this.metodo_pagos.find(
        (metodo) => metodo.id === this.metodo_pagoSelect
      ).nombre;
    },
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    buscarCliente() {
      let datos = {
        texto: this.nombre_cliente,
      };
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 300,
          });
          return;
        }
        this.clientes = [...response.clientes];
      });
    },
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    validacionFormulario() {
      if (!this.autorizado_por) {
        this.errors.autorizado_por = ["El campo autorizado por es requerido"];
      }
      if (!this.importe && this.metodo_pagoSelect == 1) {
        this.errors.importe = ["El campo importe es requerido"];
      }
      if (!this.concepto) {
        this.errors.concepto = ["El campo concepto es requerido"];
      }
      if (this.metodo_pagoSelect != 1 && !this.importe_metodos_pagos) {
        this.errors.importe_metodos_pagos = ["El campo importe es requerido"];
      }
      return Object.keys(this.errors).length === 0;
    },
    guardarMovCaja() {
      this.errors = {};
      this.enviado = true;
      if (!this.validacionFormulario()) {
        this.enviado = false;
        return;
      }
      let datos = {
        ...this.data_mov_caja,
        tipo_mov: this.tipo_mov.value,
        autorizado_por: this.autorizado_por,
        importe: this.importe ? this.importe : 0,
        concepto: this.concepto,
        caja_id: this.data_mov_caja.id,
        metodo_pago_id: this.metodo_pagoSelect,
        importe_metodos_pagos: this.importe_metodos_pagos
          ? this.importe_metodos_pagos
          : 0,
        abonar_saldo: this.abonar_saldo,
        cliente_id: this.nombre_cliente.id,
        nombre_cliente: this.nombre_cliente.nombre,
      };
      this.cajaService.newMovimientoCaja(datos).then((data) => {
        if (Object.defineProperty.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          this.errors = {};
          this.$toast.add({
            severity: "success",
            summary: "Movimiento de Caja",
            detail: data.mensaje,
            life: 3000,
          });
          this.$emit("actualizarListado");
          this.ocultarModal();
        }
        this.enviado = false;
      });
    },
    ocultarModal() {
      this.errors = {};
      this.$emit("closeModal");
      this.tipo_mov = { label: "INGRESO", value: 1 };
      this.autorizado_por = "";
      this.importe = null;
      this.concepto = "";
      this.metodo_pagoSelect = 1;
      this.abonar_saldo = false;
      this.nombre_cliente = "";
      this.enviado = false;
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    mov_caja(value) {
      this.data_mov_caja = value;
    },
    esdeodontologia(value) {
      this.es_de_odontologia = value;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
